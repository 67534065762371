<template>
  <div class="signicat-identity-wrapper">
    <b-card
      :title="this.$t('Signicat identity')"
      class="centered-text"
    >
      <b-card-text>
        <img
          v-if="status === 'success'"
          src="@/assets/images/icons/icons8-success.svg"
          alt="success"
        >
        <img
          v-if="status === 'error'"
          src="@/assets/images/icons/icons8-error.svg"
          alt="error"
        >
        <img
          v-if="status === 'loading'"
          width="48"
          height="48"
          src="@/assets/images/loaders/rolling-loader.svg"
          alt="rolling"
        >
      </b-card-text>
      <b-card-text>{{ statusText }}...<span v-if="countdown"> {{ countdown }}</span></b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      sessionId: null,
      status: 'loading',
      statusText: this.$t('Registration will continue in a few seconds'),
      countdown: null,
    }
  },
  mounted() {
    this.sessionId = this.$route.query.sessionId

    setTimeout(() => {
      this.retrieveSession(this.sessionId)
    }, 1000)
  },
  methods: {
    startCountdown(route) {
      this.countdown = 3

      const interval = setInterval(() => {
        this.countdown -= 1

        if (this.countdown <= 0) {
          clearInterval(interval)
          this.$router.push(route)
        }
      }, 1000)
    },
    async retrieveSession(sessionId) {
      this.status = 'loading'

      try {
        const payload = await this.$store.dispatch(
          'auth/retrieveIdentificationSession',
          sessionId,
        )

        this.status = 'success'

        this.startCountdown({
          name: 'user-verification',
          params: { id: payload.data.uuid },
        })
      } catch (error) {
        this.status = 'error'

        switch (error.response.status) {
          case 404:
            this.statusText = this.$t('Identification session has timed out. Please try again')
            break
          default:
            this.statusText = this.$t('An error occurred while trying to register. Please try again')
        }

        this.startCountdown({ name: 'home' })
      }
    },
  },
}
</script>

<style>
  .signicat-identity-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .centered-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
</style>
